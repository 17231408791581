import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    alizarinCrimson: "#e71d32",
    carminePink: "#DC4E45",
    deepCarminePink: "#F03434",
    amber: "#f0c200",
    dustGray: "#999999",
    azure: "#007bff",
    white: "#ffffff",
    wildSand: "#f4f4f4",
    alto: "#dadada",
    raisinBlack: "#212121",
    doveGray: "#666666",
    darkCharcoal: "#333333",
    charlestonGreen: "#292929",
    onyx: "#343a40",
    keppel: "#48a89f",
    brightGray: "#eeeeee"
  },
  alphaColors: {
    auroMetalSaurus: (alpha = "1") => `rgba(108, 117, 125, ${alpha})`,
    silver: (alpha = "1") => `rgba(199, 199, 199, ${alpha})`,
    corn: (alpha = "1") => `rgba(239, 193, 0, ${alpha})`,
    dodgerBlue: (alpha = "1") => `rgba(24, 144, 255, ${alpha})`,
    black: (alpha = "1") => `rgba(0, 0, 0, ${alpha})`,
    white: (alpha = "1") => `rgba(1, 1, 1, ${alpha})`
  },
  fontFamilies: {
    primary: "Open Sans",
    header: "Muli",
    secret: "'Space Mono', monospace"
  },
  fontSizes: {
    primary: "14px",
    subHeading: "16px",
    small: "12px",
    smaller: "10px",
    small85: "85%",
    small9: "90%"
  }
};

export default theme;
