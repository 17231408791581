import moment from "moment-timezone";
export type TimeZone = string;
export type DatePattern = string;
export type TimePattern = string;

export interface ILocalDateTimeFormatter {
  formatDateTime: (unixTime: number) => string;
  formatTime: (unixTime: number) => string;
  formatDate: (unixTime: number) => string;
}

export function createLocalDateTimeFormatter(
  timezone: TimeZone,
  dateFormat: DatePattern,
  timeFormat: TimePattern
): ILocalDateTimeFormatter {
  return new __DateTimeFormatter(timezone, dateFormat, timeFormat);
}

function isValidTimeZone(timezone: TimeZone) {
  return moment.tz.names().includes(timezone);
}

class __DateTimeFormatter implements ILocalDateTimeFormatter {
  public timezone: TimeZone;
  public dateFormat: DatePattern;
  public timeFormat: TimePattern;
  constructor(timezone: TimeZone, dateFormat: DatePattern, timeFormat: TimePattern) {
    this.dateFormat = dateFormat;
    this.timeFormat = timeFormat;
    if (isValidTimeZone(timezone)) {
      this.timezone = timezone;
    } else {
      throw new Error("Invalid Time Zone");
    }
  }

  formatDateTime(unixTime: number): string {
    const momentTimeObj = moment.unix(unixTime);
    const localMomentTimeObj = momentTimeObj.tz(this.timezone);
    const formattedDateTimeObj = localMomentTimeObj.format(this.dateFormat + " " + this.timeFormat);
    return formattedDateTimeObj;
  }
  formatDate(unixTime: number): string {
    const momentTimeObj = moment.unix(unixTime);
    const localMomentTimeObj = momentTimeObj.tz(this.timezone);
    const formattedDateObj = localMomentTimeObj.format(this.dateFormat);
    return formattedDateObj;
  }
  formatTime(unixTime: number): string {
    const momentTimeObj = moment.unix(unixTime);
    const localMomentTimeObj = momentTimeObj.tz(this.timezone);
    const formattedTimeObj = localMomentTimeObj.format(this.timeFormat);
    return formattedTimeObj;
  }
}
// TODO: Make a list of api ends points which are needed to be modified to give and receive only
// unix time stamp.

// TODO: Replace all printed date/time with new formatted DateTime in all reports

// TODO: Replace all rendered date/time with new formatted DateTime in all the UI
