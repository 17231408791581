import { ConcentrationLimitUnits, MassUnits, SamplingMethodType } from "common/enums";
import { LimitRepresentation } from "common/types/limit-representation";

export function getDefaultLimitRepresentation(): LimitRepresentation {
  return {
    productAndEquipmentLimits: {
      salMassUnit: MassUnits.mg
    },
    sampleLimits: {
      swab: {
        usePerSampleLimit: false,
        unit: ConcentrationLimitUnits.ppm
      },
      rinse: {
        usePerSampleLimit: false,
        unit: ConcentrationLimitUnits.ppm
      }
    },
    sampleRecordingLimits: {
      swab: {
        usePerSampleLimit: false,
        unit: ConcentrationLimitUnits.ppm
      },
      rinse: {
        usePerSampleLimit: false,
        unit: ConcentrationLimitUnits.ppm
      }
    },
    reporting: {
      swab: {
        showSar: false,
        showPerSampleResidue: false,
        showConcentration: true,
        perSampleLimitUnit: MassUnits.mg,
        concentrationLimitUnit: ConcentrationLimitUnits.ppm
      },
      rinse: {
        showSar: false,
        showPerSampleResidue: false,
        showConcentration: true,
        perSampleLimitUnit: MassUnits.mg,
        concentrationLimitUnit: ConcentrationLimitUnits.ppm
      }
    }
  };
}

export function isRepresentationByConcentrationRequired(
  limitRepresentation?: LimitRepresentation
): boolean {
  if (limitRepresentation) {
    return (
      !limitRepresentation.sampleLimits.swab.usePerSampleLimit ||
      !limitRepresentation.sampleRecordingLimits.swab.usePerSampleLimit ||
      limitRepresentation.reporting.swab.showConcentration
    );
  }
  return false;
}

export function isRepresentationByConcentrationOnlyRequired(
  limitRepresentation?: LimitRepresentation
): boolean {
  if (limitRepresentation) {
    return (
      !limitRepresentation.sampleLimits.swab.usePerSampleLimit &&
      !limitRepresentation.sampleLimits.rinse.usePerSampleLimit &&
      !limitRepresentation.sampleRecordingLimits.swab.usePerSampleLimit &&
      !limitRepresentation.sampleRecordingLimits.rinse.usePerSampleLimit &&
      limitRepresentation.reporting.swab.showConcentration &&
      limitRepresentation.reporting.rinse.showConcentration
    );
  }
  return false;
}

export const getL3UnitByLimitRepresentation = (
  areaUnit: string,
  l3Unit: string,
  limitRepresentation?: LimitRepresentation
): string => {
  return limitRepresentation
    ? `${limitRepresentation.productAndEquipmentLimits.salMassUnit}/${areaUnit}`
    : l3Unit;
};

export const getL5UnitByLimitRepresentation = (
  defaultUnit: string,
  samplingMethod: SamplingMethodType,
  limitRepresentation?: LimitRepresentation
): string => {
  if (limitRepresentation) {
    return samplingMethod === SamplingMethodType.Swab
      ? limitRepresentation.sampleLimits.swab.unit
      : limitRepresentation.sampleLimits.rinse.unit;
  }
  return defaultUnit;
};
