import { UUIDType } from "common/types";

export enum DateFormats {
  "MMM DD, YYYY" = "MMM DD, YYYY",
  "DD-MM-YYYY" = "DD-MM-YYYY",
  "MM-DD-YYYY" = "MM-DD-YYYY",
  "DD MMM, YYYY" = "DD MMM, YYYY",
  "DD-MMM-YYYY" = "DD-MMM-YYYY"
}

export enum TimeFormats {
  "hh:mm" = "hh:mm",
  "HH:mm" = "HH:mm",
  "hh:mm:ss" = "hh:mm:ss",
  "HH:mm:ss" = "HH:mm:ss"
}

export type DateTimeConfig = {
    facilityId?: UUIDType;
    dateFormat: DateFormats;
    timeFormat: TimeFormats;
    timezone: string;
    country: string;
    doesShowSecond?:boolean
};
