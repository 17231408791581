import { Collapse } from "antd";
import styled from "styled-components";

export const FacilityListView = styled.div`
  width: 350px;
  overflow: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const FacilityTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  background: ${props => props.theme.alphaColors.dodgerBlue()};
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
`;

export const FacilityGroupCollapse = styled(Collapse)`
  max-height: 400px;
  overflow: auto;
  border-radius: 0px 0px 10px 10px;

  .ant-collapse-content-box {
    padding: 0 8px !important;
  }

  .ant-collapse-header {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: ${props => props.theme.colors.azure} !important;
  }
`;

export const FacilitySelector = styled.div`
  padding: 10px;
  height: 50px;
  cursor: pointer;
  background: ${props => props.theme.alphaColors.dodgerBlue("0.141")};
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;

  :hover {
    background: ${props => props.theme.alphaColors.dodgerBlue("0.392")};
  }
`;
