import { observable, action } from "mobx";
import {
  TimeFormats,
  DateFormats,
} from "common/types/date-time-format";
import { getDateTimeConfig } from "client/scenes/Home/scenes/AdminSettings/scenes/DateTimeSettings/DateTimeMetadata";
import { UUIDType } from "common/types";

type DateTimeConfig = {
  facilityId?: UUIDType;
  dateFormat: DateFormats;
  timeFormat: TimeFormats;
  timezone: string;
  country: string;
  doesShowSecond?:boolean
};

class DefaultDateTimeFormats {
  @observable
  defaultFormats: DateTimeConfig = {
    timezone: "UTC",
    dateFormat: DateFormats["DD MMM, YYYY"],
    timeFormat: TimeFormats["HH:mm"],
    country: ""
  };

  @action
  setDefaultFormats = async () => {
    const formatInfo = await getDateTimeConfig();
    this.defaultFormats = formatInfo.dateTimeSettings;
  };
}

export const DefaultFormats = new DefaultDateTimeFormats();
