import { FeatureFlagsType } from "common/types";
import React from "react";
import Routes from "./routes";

type Props = {
  handleLogin: () => void;
  featureFlags?: FeatureFlagsType;
};

export const Auth = (props: Props) => {
  return <Routes {...props} />;
};
