import styled from "styled-components";

type CommonLayoutProps = {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  x?: string;
  y?: string;
  all?: string;
};

type CommonDimensionProps = {
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  maxHeight?: string;
  minHeight?: string;
  height?: string;
};

export const PaddedWrapper = styled.div<CommonLayoutProps>`
  ${props => (props.left ? `padding-left: ${props.left};` : "")}
  ${props => (props.x ? `padding-left: ${props.x};` : "")}
  ${props => (props.right ? `padding-right: ${props.right};` : "")}
  ${props => (props.y ? `padding-right: ${props.y};` : "")}
  ${props => (props.top ? `padding-top: ${props.top};` : "")}
  ${props => (props.bottom ? `padding-bottom: ${props.bottom};` : "")}
  ${props => (props.all ? `padding: ${props.all};` : "")}
`;

export const MarginWrapper = styled.div<CommonLayoutProps>`
  ${props => (props.left ? `margin-left: ${props.left};` : "")}
  ${props => (props.x ? `margin-left: ${props.x};` : "")}
  ${props => (props.right ? `margin-right: ${props.right};` : "")}
  ${props => (props.y ? `margin-right: ${props.y};` : "")}
  ${props => (props.top ? `margin-top: ${props.top};` : "")}
  ${props => (props.bottom ? `margin-bottom: ${props.bottom};` : "")}
  ${props => (props.all ? `margin: ${props.all};` : "")}
`;

export const DimensionWrapper = styled.div<CommonDimensionProps>`
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth};` : "")}
  ${props => (props.minWidth ? `min-width: ${props.minWidth};` : "")}
  ${props => (props.width ? `width: ${props.width};` : "")}
  ${props => (props.maxHeight ? `max-height: ${props.maxHeight};` : "")}
  ${props => (props.minHeight ? `min-height: ${props.minHeight};` : "")}
  ${props => (props.height ? `height: ${props.height};` : "")}
`;

export const WeightedWrapper = styled.span<{
  weight?: number;
}>`
  ${props => (props.weight ? `font-weight: ${props.weight};` : "")}
`;

export const OpacityWrapper = styled.div<{
  opacity?: number;
}>`
  ${props => (props.opacity ? `opacity: ${props.opacity};` : "")}
`;
