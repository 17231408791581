import { getCurrentUser } from "client/services/api";
import { UserProvider } from "common/enums";
import { FacilityVO } from "common/types";
import { DepartmentVO, UserStatus } from "common/types/user";
import { UserRoleVO } from "common/types/user-role";
import { action, observable } from "mobx";

type UserVO = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email?: string;
  department: DepartmentVO;
  departmentId: number;
  imposter: boolean;
  designation: string;
  isBlocked: boolean;
  userBlockedOn?: Date;
  usingTempPassword: boolean;
  isVerified: boolean;
  enabled: boolean;
  facilities: FacilityVO[];
  roles: UserRoleVO[];
  root: boolean;
  status: UserStatus;
  provider: UserProvider;
};

class UserStore {
  @observable
  isUserLoggedIn = false;
  @observable
  currentPlan = "production";
  @observable
  // TODO Add proper type for currentUser
  currentUser: UserVO = {} as unknown as UserVO;

  @action
  setCurrentUser = async () => {
    this.currentUser = await getCurrentUser();
  };
}

const store = (window["UserStore"] = new UserStore());

export default store;
