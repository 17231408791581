import axios, { AxiosRequestConfig } from "axios";

export class HttpClient {
  static requestConfig(optionsIn?: { [index: string]: any }) {
    const options = optionsIn || {};
    const doNotCache = options.doNotCache;

    type headerType = {};

    const headers: headerType = {};

    if (doNotCache) {
      headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
      headers["Pragma"] = "no-cache";
      headers["Expires"] = 0;
    }

    // headers["Cookie"] = `${COOKIE_NAME}=${sessionStorage.getItem(COOKIE_NAME)}`;

    return {
      headers
    };
  }

  static async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = await axios.post<T>(url, data, config);
    return response;
  }

  static async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = await axios.put<T>(url, data, config);
    return response;
  }

  static async get<T = any>(url: string, config?: AxiosRequestConfig) {
    const response = await axios.get<T>(url, config);
    return response;
  }
  static async delete<T = any>(url: string, config?: AxiosRequestConfig) {
    const response = await axios.request<T>({ ...config, method: "delete", url });
    return response;
  }
}
