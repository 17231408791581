import { Collapse } from "antd";
import { FacilityType } from "common/types";
import React, { Component } from "react";
import { FacilityGroupCollapse, FacilityListView, FacilitySelector, FacilityTitle } from "./styles";
const { Panel } = Collapse;

export type FacilityGroupType = {
  name: string;
  id?: string;
  facilities: FacilityType[];
};

type Props = {
  facilityGroups: FacilityGroupType[];
  setFacility: (arg: FacilityType) => void;
};

type State = {};

class FacilityList extends Component<Props, State> {
  render() {
    const { facilityGroups } = this.props;
    const facilityCards =
      facilityGroups &&
      facilityGroups.map((group, i) => (
        <Panel key={i.toString()} header={group.name}>
          {group.facilities &&
            group.facilities.map((facility, key) => (
              <FacilitySelector key={key} onClick={e => this.props.setFacility(facility)}>
                {facility.name}
              </FacilitySelector>
            ))}
        </Panel>
      ));
    const content = facilityCards && (
      <FacilityListView>
        <FacilityTitle>Choose Facility</FacilityTitle>
        <FacilityGroupCollapse accordion={true} defaultActiveKey={["0"]}>
          {facilityCards}
        </FacilityGroupCollapse>
      </FacilityListView>
    );
    return content;
  }
}

export default FacilityList;
