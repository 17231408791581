import { observable, action } from "mobx";
import { unitsShortName } from "common/utils/default-units";
import { getDefaultUnits } from "client/services/api";

type Unit = {
  id: number;
  name: string;
  shortName: string;
  unit: string;
};

class DefaultUnits {
  @observable
  defaultUnits: { [t: string]: string } = {};

  @observable
  defaultUnitsObject: Unit[] = [];

  @action
  getDefaultUnit = (unitKey: unitsShortName) => {
    return this.defaultUnits[unitKey] || "";
  };

  @action
  setAllDefaultUnits = async () => {
    const defaultUnits = await getDefaultUnits();
    this.defaultUnitsObject = defaultUnits;

    defaultUnits.forEach((unitObj: Unit) => {
      this.defaultUnits[unitObj.shortName] = unitObj.unit;
    });
  };
}

export type { Unit };
export const defaultUnitState = new DefaultUnits();
